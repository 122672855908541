import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'dev',

  featureFlags: {
    ...base.featureFlags,
  },
  ecomm: {
    ...base.ecomm
  },
};
